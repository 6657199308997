import React from 'react'

export const Footer = () => {
  return (
    <footer className='container text-center'>
      <div className="row mt-5">
        <div className="col-12">
          <h5 className=''>Hecho por Daniel Gutierrez</h5>
        </div>
      </div>
      
      
    </footer>
  )
}
