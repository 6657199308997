import React from "react";
import { Routes, Route, Navigate, useLocation} from "react-router-dom";
import { Inicio } from "../components/Inicio";
import { Aboutme } from "../components/Aboutme";
import { Portfolio } from "../components/Portfolio";
import { Blog } from "../components/Blog";
import { Contacto } from "../components/Contacto";
import { Error } from "../components/Error";
import { Main } from "../components/Main";
import { HeaderNav } from "../components/layout/HeaderNav";
import { Footer } from "../components/layout/Footer";
import { Maines } from "../components/Daniel/Maines";
import { Basica } from "../components/Basica/Basica";
export const MisRutas = () => {
  
  const location = useLocation();
  // Rutas donde no se quiere mostrar el Header y Footer
  const hideHeaderFooterRoutes = ["/tarjeta-expo-provincias","/Daniel-Gutierrez","/tarjeta-basica"];

  const shouldShowHeaderFooter = !hideHeaderFooterRoutes.includes(location.pathname);

  return (
    <>
    {/* Mostrar Header si no está en las rutas excluidas */}
    {shouldShowHeaderFooter && <HeaderNav />}

    {/* Contenido Central */}
    <section className="content">
      <Routes>
        <Route path="/" element={<Navigate to="/inicio" />} />
        <Route path="/inicio" element={<Inicio />} />
        <Route path="/Aboutme" element={<Aboutme />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/tarjeta-expo-provincias" element={<Main />} />
        <Route path="/Daniel-Gutierrez" element={<Maines/>} />
        <Route path="/tarjeta-basica" element={<Basica/>} />
        <Route path="*" element={<Error />} />
      </Routes>
    </section>

    {/* Mostrar Footer si no está en las rutas excluidas */}
    {shouldShowHeaderFooter && <Footer />}
  </>
  );
};
