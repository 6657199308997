import React from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/images/DG.png'
export const HeaderNav = () => {
  return (
    <header className='container bg-body-tertiary '>
      <div className="row navbar">
        <div className="col-6">
        <a class="navbar-brand" href="/">
      <img src={logo} alt="Logo" width="50" height="50" class="d-inline-block align-middle"/>
      Daniel Gutierrez
    </a>
        </div>
        <div className="col-6">
        <nav>
            <ul className='nav nav-pills'>
                <li className='nav-item'>
                    <NavLink to="/inicio" className="nav-link" aria-current="page">Inicio</NavLink>
                </li>
                <li className='nav-item'>
                    <NavLink to="/aboutme" className="nav-link">About me</NavLink>
                </li>
                <li className='nav-item'>
                    <NavLink to="/portfolio" className="nav-link">Portfolio</NavLink>
                </li>
                <li className='nav-item'>
                    <NavLink to="/blog" className="nav-link">Blog</NavLink>
                </li>
                <li className='nav-item'>
                    <NavLink to="/contacto" className="nav-link">Contact</NavLink>
                </li>
            </ul>
        </nav>
        </div>
      </div> 
    </header>
  )
}
