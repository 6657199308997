import React from 'react'
import portada from "../../assets/images/portadabasic.jpg"
import men from "../../assets/images/perfil.jpg"
export const Basica = () => {
  return (
    <div>
        <div className="container  justify-content-center align-items-center ">
            <div className="row">
                <div className="col-12">
                <img src={portada} alt="Descripción" className="img-fluid" />
                </div>
                <div className="col-12">
                <img src={men} alt="Descripción" className="img-fluid rounded-circle border border-3 d-none d-md-none d-lg-inline profile-pic-m " style={{maxWidth: '400px'}}/>
                </div>
            </div>
        </div>
    </div>
  )
}
