import React from 'react'
import perfil from '../assets/images/perfil.jpg'
import programando from '../assets/images/programando.jpg'
import computador from '../assets/images/computador.jpg'
import codigo from '../assets/images/codigo.jpg'
export const Inicio = () => {
  return (
    <div className='container ' >
      <div className="row mt-5 ">
        <div className="col-7 ">
          <section className='w-100'>
            {/*Heroes*/}
          <h1>Daniel Gutierrez</h1>
          <p>Soy un ingeniero electronico de la universidad industrial de santander y soy backend developer</p>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quae impedit nemo molestias asperiores sunt ad repellat velit corrupti, ipsum saepe ea vitae illum perspiciatis possimus, dignissimos repudiandae, accusantium libero? In.</p> 
          </section>
        </div>
        <div className="col-3">
        <img src={perfil} className="rounded  w-100" alt="..."/>
        </div>
      </div>
      <div className="row ">
        <section>
        {/*Seccion de tecnologias y habilidades*/}
        <h1 className=' '>Tecnologias y habilidades</h1>
        <ul>
          <li>React.js</li>
          <li>Node.js</li>
          <li>Mongo</li>
        </ul>
        </section>
        
      </div>
      <div className="row">
        {/*Experiencia*/}
        <div className="row">
          <div className="col-12">
          <h2>Experiencia</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
          <h4>Under canvas</h4>
          <h5>2022-2023</h5>
          <p>Ayude en el desarrolo de la pagina web de la comania</p>
          </div>
          <div className="col-6">
          <h4>Under canvas</h4>
          <h5>2022-2023</h5>
          <p>Ayude en el desarrolo de la pagina web de la comania</p>
          </div>
        </div>
      </div>
      <div className="row">
        {/*Some proyects*/}
        <div className="row">
          <h3>Some proyects</h3>
        </div>
        <div className="row">
          <div className="col-4">
          <img src={programando} className="rounded  w-100" alt="..."/>
          </div>
          <div className="col-4">
          <img src={computador} className="rounded  w-100" alt="..."/>
          </div>
          <div className="col-4">
          <img src={codigo} className="rounded  w-100" alt="..."/>
          </div>
        </div>
      </div>
    </div>
  )
}
