import React from "react";

import artesaniasLogo from "../assets/images/Logo_artesanias.PNG";
import chicamocha from "../assets/images/chicamocha.jpg"
import logo_expo from "../assets/images/logo.png"


import yariquies1 from "../assets/images/yariquies1.jpeg";
import yariquies2 from "../assets/images/yariquies2.jpeg";
import yariquies3 from "../assets/images/yariquies3.jpeg";
import yariquies4 from "../assets/images/yariquies4.jpg";

import charala1 from "../assets/images/charala1.jpeg";
import charala2 from "../assets/images/charala2.jpeg";
import charala3 from "../assets/images/charala3.jpeg";
import charala4 from "../assets/images/charala4.jpeg";

import soto1 from "../assets/images/soto1.jpeg";
import soto2 from "../assets/images/soto2.jpeg";
import soto3 from "../assets/images/soto3.jpeg";
import soto4 from "../assets/images/soto4.jpeg";

import velez1 from "../assets/images/velez1.jpeg";
import velez2 from "../assets/images/velez2.jpeg";
import velez3 from "../assets/images/velez3.jpg";
import velez4 from "../assets/images/velez4.jpeg";

import garcia1 from "../assets/images/garcia1.jpeg";
import garcia2 from "../assets/images/garcia2.jpeg";
import garcia3 from "../assets/images/garcia3.jpeg";

import bucaramanga1 from "../assets/images/bucaramanga1.jpg";
import bucaramanga2 from "../assets/images/bucaramanga2.jpg";
import bucaramanga3 from "../assets/images/bucaramanga3.jpg";

import tallerpsico from "../assets/images/tallerpsico.PNG"
import tallerinven from "../assets/images/tallerinven.PNG"
import tallermario from "../assets/images/tallermario.PNG"
import ofertacultural from "../assets/images/ofertacultural.PNG"
import entretenimiento from "../assets/images/Entretenimiento.PNG"




import { Compartir } from "./Compartir";
import { Provincia } from "./Provincia";
import { Musica } from "./Musica";


export const Main = () => {
  const imagesYariquies = [
    { src: yariquies1, alt: "yariquies1" },
    { src: yariquies2, alt: "yariquies2" },
    { src: yariquies3, alt: "yariquies3" },
    { src: yariquies4, alt: "yariquies4" }
  ];
  const imagesGuanenta = [
    { src: charala1, alt: "charala1" },
    { src: charala2, alt: "charala2" },
    { src: charala3, alt: "charala3" },
    { src: charala4, alt: "charala4" }
    
  ];
  const imagesMetropolitana = [
    { src: soto1, alt: "soto1" },
    { src: soto2, alt: "soto2" },
    { src: soto3, alt: "soto3" },
    { src: soto4, alt: "soto4" }
    
  ];
  const imagesGarcia = [
    { src: garcia1, alt: "garcia1" },
    { src: garcia2, alt: "garcia2" },
    { src: garcia3, alt: "garcia3" } 
  ];
  const imagesVelez = [
    { src: velez1, alt: "velez1" },
    { src: velez2, alt: "velez2" },
    { src: velez3, alt: "velez3" },
    { src: velez4, alt: "velez4" }
  ];
  const imagesFinal = [
    { src: bucaramanga1, alt: "bucaramanga1" },
    { src: bucaramanga2, alt: "bucaramanga2" },
    { src: bucaramanga3, alt: "bucaramanga3" }
    
  ];
 
  const yariquiescalen = {
    municipio: "BARRANCABERMEJA",
    artesanos: "50",
    bocados: "30",
    fecha: "OCTUBRE 12, 13, 14"
  }
  const guanentacalen = {
    municipio: "CHARALÁ",
    artesanos: "80",
    bocados: "40",
    fecha: "NOVIEMBRE 2, 3, 4"
  }
  const metropolitanacalen = {
    municipio: "ZAPATOCA",
    artesanos: "60",
    bocados: "40",
    fecha: "OCTUBRE 12, 13, 14"
  }
  const garciacalen = {
    municipio: "MÁLAGA",
    artesanos: "50",
    bocados: "30",
    fecha: "NOVIEMBRE 9, 10, 11"
  }
  const velezcalen = {
    municipio: "PUENTE NACIONAL",
    artesanos: "50",
    bocados: "30",
    fecha: "NOVIEMBRE 23, 24"
  }
  const finalcalen = {
    municipio: "BUCARAMANGA",
    artesanos: "",
    bocados: "",
    fecha: "DICIEMBRE 6, 7, 8"
  }
  return (
    <div className="container-fluid d-flex align-items-center justify-content-center" style={{ 
      backgroundImage: `url(${chicamocha})`,
      backgroundSize: 'cover', 
      minHeight: '100vh',
      padding: '20px'
    }}>
      <div className="card mt-5" style={{ 
          backgroundColor: 'rgb(0, 35, 73,0.98)',  // Fondo semitransparente
          borderRadius: '15px',
          padding: '20px',
          maxWidth: '900px' 
        }}>
        <img src={chicamocha} alt="chicamocha" className="cover-image img-fluid" />
        <div className="row">
          <div className="col-3 col-lg-4"></div>
          <div className="col-6 col-lg-4 d-flex justify-content-center">
            <div className="rounded-circle ">
              <img
                src={logo_expo}
                alt=""
                className="img-fluid d-sm-inline d-md-none  profile-pic rounded-circle border border-3 border-dark mx-auto"
                style={{ maxWidth: '250px' }} 
              />
              <img
                src={logo_expo}
                alt=""
                className="img-fluid d-none d-md-inline d-lg-none profile-pic-m rounded-circle border border-3 border-secondary"
              />
              <img
                src={logo_expo}
                alt=""
                className="img-fluid d-none d-md-none d-lg-inline profile-pic-l rounded-circle border border-3 border-secondary animate__animated animate__fadeInDown"
              />
            </div>
          </div>
          <div className="col-3 col-lg-4"></div>
        </div>

        <div className="card-body text-center pt-0 text-white">
        <div className="row justify-content-center text-center pt-3 pb-2 mb-3 rounded  p-3">
  {/* Columna para la imagen */}
  
          <h1 className="card-title tarjeta-expo-provincias animate__animated animate__bounce">Expo-Provincias</h1>
          <h5 className="card-text text-white">
          HECHO EN SANTANDER 
          </h5>
          
          
          <div className="row ">
            <div className="col-1"></div>
            <div className="col-12">
              <a href="https://wa.me/573218326498" target="_blank" rel="noopener noreferrer"className="btn  btn-success mx-1 btn-lg">
                <i className="bi bi-whatsapp fs-4"></i>
              </a> 
              <a href="tel:+573218326498" className="btn btn-info mx-1 btn-lg">
              <i className="bi bi-telephone fs-4"></i>
              </a>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="col-auto">
    <img
      src={artesaniasLogo}
      alt="Artesanías"
      className="img-fluid rounded bg-light p-3 mt-5 animate__animated animate__jackInTheBox"
      style={{ maxWidth: '90px' }} // Ajusta el tamaño de la imagen
    />
  </div>

  {/* Columna para el texto "APOYA" */}
  <div className="col-12 mt-3">
    <h5 className="mb-0 text-white">APOYA</h5>
  </div>
</div>
        

        <div className="row mt-4 ">
          
              <h2 className="card-title text-white"><i class="bi bi-house-heart-fill"></i>¿Cuál es nuestra motivación?</h2>
              <p className="card-text fs-5  text-white">
              Las ferias Provinciales nacen de la necesidad de hacer visible el oficio y tradición de saberes  llegando de manera estratégica a todos los territorios del Departamento de Santander considerando y entrelazando la dimensión cultural, social  y económica .
              </p>
            
        </div>
        <div className="row">
        <div className="container mt-5">

    </div>
        </div>
      
        <div className="row mt pb-5">
        <div className="container mt-2">
  {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary animate__animated animate__fadeInLeft"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#metodologiaCollapse"
    aria-expanded="false"
    aria-controls="metodologiaCollapse"
  >
    <i class="bi bi-filter-circle"></i>   METODOLOGÍA
  </button>
  
  {/* Contenido que colapsa */}
  <div className="collapse mt-3" id="metodologiaCollapse">
    <div className="card card-body bg-primary">
      Se realizarán 7 Ferias con distribución en cada una de las provincias,
      contando con la presencia de 50 artesanos y 30 bocados de cada región en
      cada una de ellas, con el objetivo de visibilizar el trabajo artesanal y
      Turístico del Departamento, seleccionando los 10 artesanos y bocados que
      representarán a la provincia en la Gran Feria Expo provincial en la
      Capital del Departamento.
    </div>
  </div>
</div>
        </div>

        <hr/>

        <div className="row mt pb-5 bg-primary pt-5 rounded  p-3">
        <div className="container mt-2">
  {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary mb-5"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#actividadesCollapse"
    aria-expanded="false"
    aria-controls="actividadesCollapse"
  >
   <i class="bi bi-list-ol"></i>   ACTIVIDADES A REALIZAR
  </button>

  {/* Contenido que colapsa */}
  <div className="collapse mt-3" id="actividadesCollapse">
    <div className="card card-body bg-primary">
      <div className="row">
        <div className="col-12 mb-4">
           {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#actividadesCollapse1"
    aria-expanded="false"
    aria-controls="actividadesCollapse1"
  >
    1. Taller psicosocial: El poder de la intención positiva.
  </button>
  <div className="collapse mt-3" id="actividadesCollapse1">
  <div className="card card-body">
      <div className="row">
        <div className="col-12 mb-4">
          <img src={tallerpsico} class="img-fluid" alt="..."></img>
          <p className="pt-5 text-dark"><strong className="text-danger">Objetivo:</strong> Brindar a los artesanos un espacio de activación y preparación pre feria para poder potenciar sus habilidades de comunicación asertiva, manejo de objeciones y disposición emocional para potenciar las ventas y ganancias en la feria.</p>

          </div>
          </div>
          </div>
         
         </div>
         
        </div>
        
        <div className="col-12 mb-4">
           {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#actividadesCollapse2"
    aria-expanded="false"
    aria-controls="actividadesCollapse2"
  >
    2. Taller de  inventarios,  Estrategias y Discurso de Ventas, Pitch Empresarial.
  </button>
  <div className="collapse mt-3" id="actividadesCollapse2">
  <div className="card card-body">
      <div className="row">
        <div className="col-12 mb-4">
          <img src={tallerinven} class="img-fluid" alt="..."></img>
          <p className="pt-5 text-dark"><strong className="text-danger">Objetivo:</strong> Capacitar a los artesanos en la gestión de inventarios artesanales en la feria, además de la transmisión de herramientas para poder dominar un adecuado discurso de ventas y manejo de objeciones.</p>

          </div>
          </div>
          </div>
         
         </div>
         
        </div>
        <div className="col-12 mb-4">
           {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#actividadesCollapse3"
    aria-expanded="false"
    aria-controls="actividadesCollapse3"
  >
    3. Taller  para niños  Armando mi Títere Artesanal.  Sembrando el amor por nuestras Artesanías.
  </button>
  <div className="collapse mt-3" id="actividadesCollapse3">
  <div className="card card-body">
      <div className="row">
        <div className="col-12 mb-4">
          <img src={tallermario} class="img-fluid" alt="..."></img>
          <p className="pt-5 text-dark"><strong className="text-danger">Objetivo:</strong>  Por medio de la elaboración del títere y la técnica del teatrino, se aborda de forma didáctica la importancia del oficio artesanal y la transmisión de saberes a las nuevas generaciones como son los niños, hijos de artesanos, visitantes en la feria y residentes en los municipios de Santander.</p>

          </div>
          </div>
          </div>
         
         </div>
         
        </div>

        <div className="col-12 mb-4">
           {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#actividadesCollapse4"
    aria-expanded="false"
    aria-controls="actividadesCollapse4"
  >
    4. Oferta cultural
  </button>
  <div className="collapse mt-3" id="actividadesCollapse4">
  <div className="card card-body">
      <div className="row">
        <div className="col-12 mb-4">
          <img src={ofertacultural} class="img-fluid" alt="..."></img>
          <p className="pt-5 text-dark"><strong className="text-danger">Objetivo:</strong>  En cada edición de Expo-Provincias, en la tarima se tendrá la participación de los grupos folclóricos, musicales, de danza y entretenimiento autóctonos de la provincia y de Santander, con la intención de poder brindar oportunidades a los nuevos grupos de artistas que tenga la provincia. 

</p>

          </div>
          </div>
          </div>
         
         </div>
         
        </div>
        
        <div className="col-12 mb-4">
           {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#actividadesCollapse5"
    aria-expanded="false"
    aria-controls="actividadesCollapse5"
  >
    5. Entretenimiento
  </button>
  <div className="collapse mt-3" id="actividadesCollapse5">
  <div className="card card-body">
      <div className="row">
        <div className="col-12 mb-4">
          <img src={entretenimiento} class="img-fluid" alt="..."></img>
          <p className="pt-5 text-dark"><strong className="text-danger">Objetivo:</strong>  En cada edición de Expo-Provincias, en la tarima se dará espacio para realizar competencias sanas de juegos típicos de Santander, como lo es el Bolo Criollo, el Tejo, y Rana.
          </p>

          </div>
          </div>
          </div>
         
         </div>
         
        </div>
        
      </div>
      
    </div>
    
  </div>
  <hr/>
</div>
        </div>
        <div className="row mt pb-5 pt bg-primary rounded  p-3">
        <div className="container mt-5 ">
  {/* Botón que activa el colapso */}
  <button
    className="btn btn-secondary mb-5"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#ofertaCollapse"
    aria-expanded="false"
    aria-controls="ofertaCollapse"
  >
   <i class="bi bi-award"></i> OFERTA INSTITUCIONAL NACIONAL
  </button>

  {/* Contenido que colapsa */}
  <div className="collapse mt-3 " id="ofertaCollapse">
    <div className="card card-body bg-primary rounded  p-3">
    <ul className="list-group ">
  <li className="list-group-item bg-primary text-white">Ministerio del INTERIOR (ASOJUNTAS)</li>
  <li className="list-group-item bg-primary text-white">Ministerio de SALUD</li>
  <li className="list-group-item bg-primary text-white">Prosperidad Social</li>
  <li className="list-group-item bg-primary text-white">Ministerio de Comercio Industria y Turismo</li>
  <li className="list-group-item bg-primary text-white">Impulsa</li>
  <li className="list-group-item bg-primary text-white">Agencia Nacional de Tierras</li>
  <li className="list-group-item bg-primary text-white">Superintendencia de Servicios Públicos</li>
  <li className="list-group-item bg-primary text-white">Ministerio de Cultura</li>
  <li className="list-group-item bg-primary text-white">Invias</li>
  <li className="list-group-item bg-primary text-white">SENA</li>
</ul>
    </div>
  </div>
</div>
        </div>
        <hr/>

        <div className="row mt-3 bg-primary text-secondary rounded  p-3">
            <h2 className="mt-3 text-white">CALENDARIO DE  FERIAS ARTESANALES Y  CULTURALES</h2>
            <div className="col-12 col-lg-6">
                <Provincia name="YARIQUIES" idcolapse="YARIQUIES" images={imagesYariquies} idcarru="carruyariquies" calendario={yariquiescalen}/>
            </div>
            <div className="col-12 col-lg-6">
                <Provincia name="GUANENTÁ Y COMUNERA" idcolapse="GUANENTA" images={imagesGuanenta} idcarru="carruGuanenta" calendario={guanentacalen}/>
            </div>
            <div className="col-12 col-lg-6">
                <Provincia name="METROPOLITANA Y SOTO NORTE" idcolapse="METROPOLITANA" images={imagesMetropolitana} idcarru="carruMetropolitana" calendario={metropolitanacalen}/>
            </div>
            <div className="col-12 col-lg-6">
                <Provincia name="GARCIA ROVIRA" idcolapse="GARCIA" images={imagesGarcia} idcarru="carruGarcia" calendario={garciacalen}/>
            </div>
            <div className="col-12 col-lg-6">
                <Provincia name="VÉLEZ" idcolapse="VELEZ" images={imagesVelez} idcarru="carruVelez" calendario={velezcalen}/>
            </div>
            <div className="col-12 col-lg-6 mb-3">
                <Provincia name="FINAL" idcolapse="FINAL" images={imagesFinal} idcarru="carruFinal" calendario={finalcalen}/><i class="bi bi-flower1"></i>
            </div>
        </div>
    


        <div className="row mt-4">
        <Compartir/>
        </div>

        
      </div>
    </div>
    </div>
  );
};
