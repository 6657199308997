import React from "react";

import robot from "../../assets/videos/robot.mp4"


import pagina2 from "../../assets/images/pagina2.jpg"
import pagina3 from "../../assets/images/pagina3.jpg"

import tarjeta1 from "../../assets/images/tarjeta1.png"
import tarjeta2 from "../../assets/images/tarjeta2.jpg"


import rifa from "../../assets/images/rifa.png"
import { Servicio } from "./ServicioV";
import { Compartir } from "../Compartir";

export const Maines = () => {
  const images = [
    { src: tarjeta1, alt: "tarjeta1" },
    { src: tarjeta2, alt: "tarjeta2" }
  
  ];
  const images2 = [
    { src: pagina2, alt: "pagina2" },
    { src: pagina3, alt: "pagina3" }
  ];
  

  
  return (
    <div className="container  justify-content-center align-items-center ">
      <div className="card mt-5 bg-primary">
        {/* Reemplazar la imagen de portada con un video */}
        <video autoPlay muted loop className="cover-video">
          <source src={robot} type="video/mp4" />
          Tu navegador no soporta la reproducción de videos.
        </video>
        <div className="row">
          <div className="col-3 col-lg-4"></div>
          <div className="col-6 col-lg-4"></div>
          <div className="col-3 col-lg-4"></div>
        </div>

        <div className="card-body text-center pt-0">
          <h1 className="card-title text-danger animate__animated animate__fadeInLeft">Daniel Gutiérrez</h1>
          <p className="card-text text-warning">Estudiante de Ingeniería Electrónica</p>
          <div className="row ">
            
            <div className="col-12">
              <a href="https://wa.me/573174060597" className="btn btn-secondary mx-1" style={{ maxWidth: '160px' }} target="_blank" rel="noopener noreferrer">
                <i className="bi bi-whatsapp text-white fs-5" ></i>
              </a>
            </div>

          </div>
          

          <div className="row mt-4">
            <div className="card bg-primary">
              <div className="card-body">
                <h2 className="card-title text-warning">Ayúdame a ir de intercambio</h2>
                <p className="card-text fs-5  text-white">
                Soy estudiante de ingeniería electrónica con una pasión por la programación, ayúdame comprando un puesto en la <strong>rifa</strong> o comprando una <strong>tarjeta digital</strong> o una <strong>pagina web</strong> para tu emprendimiento.
                </p>
              </div>
            </div>
          </div>

          <div className="row mt-4">
          <img src={rifa} alt="Descripción" className="img-fluid" />
        </div>
              </div>
              <hr/>
            <div className="row ">
              <div className="col-12 text-center">
              <h1 className="text-danger">SERVICIOS</h1>
              <hr/>
              <Servicio subservice="Tarjeta digital" images={images} id="1" />
              <h2 className="text-success">Precio: 85.000</h2>
              <p className="text-white">Transforma tu presentación profesional con una <strong>Tarjeta Digital</strong>. Olvídate de las tarjetas físicas que se pierden o se desgastan, y opta por una solución moderna y ecológica. Nuestra tarjeta digital incluye tu información de contacto, enlaces a tus redes sociales, y la posibilidad de compartirla fácilmente con solo un toque. Personalizable y siempre accesible desde cualquier dispositivo móvil, ¡lleva tu marca a donde quiera que vayas!</p>
              <button
    className="btn btn-success animate__animated animate__fadeInLeft "
    type="button" onClick={() => window.open('https://wa.me/573174060597', '_blank')}
  >
    Quiero mi tarjeta digital !!!
  </button>
              <hr/>
              <Servicio subservice="Página Web" images={images2} id="2" />
              <h2 className="text-success">Precio: 500.000</h2>
              <p className="text-white">Lleva tu emprendimiento, empresa o marca personal a la web con una página completamente personalizada y diseñada a medida para destacar lo mejor de ti. Este sitio web se adapta a tus necesidades específicas.
              Optimizada para cualquier dispositivo y diseñada con un enfoque moderno y profesional, tu página web te ayudará a conectar con más personas, potenciar tu presencia en línea y hacer crecer tu proyecto.
              </p>
              <button
    className="btn btn-success animate__animated animate__fadeInLeft "
    type="button" onClick={() => window.open('https://wa.me/573174060597', '_blank')}
  >
    Quiero mi página web !!!
  </button>
              </div>
              
            </div>
          <div className="row">
            <Compartir nombre="Tarjeta Daniel Gutierrez"/>
            
          </div>
        </div>
      </div>
    
  );
};
