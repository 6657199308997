import React from 'react'
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
  } from 'react-share';
export const Compartir = ({nombre="Mi Tarjeta Digital"}) => {
    const shareUrl = window.location.href;
    const title ="Mi Tarjeta Digital";

  return (
    <div>
            <div className="text-center mt-5">
      <h3 className='text-danger'>¡Comparte esta página!</h3>

      <div>
        <FacebookShareButton url={shareUrl} quote={title} className="mx-2">
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        <TwitterShareButton url={shareUrl} title={title} className="mx-2">
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <WhatsappShareButton url={shareUrl} title={title} separator=":: " className="mx-2">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
    </div>
  )
}
