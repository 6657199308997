import React, { useState } from 'react';

export const Provincia = ({ name, idcolapse, idcarru, images, calendario }) => {
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const handleImageClick = (src) => {
    setFullscreenImage(src);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  return (
    <div className="container mt-5">
      {/* Botón que activa el colapso */}
      <button
        className="btn btn-secondary custom-button"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={'#' + idcolapse}
        aria-expanded="false"
        aria-controls={idcolapse}
      >
        {name}
      </button>

      {/* Contenido que colapsa */}
      <div className="collapse mt-3" id={idcolapse}>
        {/* Tabla */}
        <div className="card card-body bg-primary">
          <h7 className="text-white">{calendario.municipio}</h7>
          <hr />
          <h7 className="text-white">Artesanos: {calendario.artesanos}</h7>
          <hr />
          <h7 className="text-white">Bocados: {calendario.artesanos}</h7>
          <hr />
          <h7 className="text-white">{calendario.fecha}</h7>
          <hr />

          {/* Carrusel de Imágenes */}
          <div id={idcarru} className="carousel slide mt-4" data-bs-ride="carousel">
            <div className="carousel-inner">
              {images.map((image, index) => (
                <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                  <img
                    src={image.src}
                    className="d-block w-100"
                    alt={image.alt}
                    onClick={() => handleImageClick(image.src)} // Agregar manejador de clic
                    style={{ cursor: 'pointer' }} // Cambiar el cursor para indicar que es clickeable
                  />
                </div>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target={'#' + idcarru} data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={'#' + idcarru} data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

          {/* Descripción */}
          <div className="mt-4">
            <h5 className="text-white">Descripción</h5>
            <p className="bg-secondary rounded p-3 text-dark">
              Estas ferias se realizarán en diversos municipios del departamento para promover el trabajo
              artesanal y la cultura gastronómica local. Se seleccionarán los mejores productos y artesanos para
              participar en una gran feria provincial que se llevará a cabo en la capital del departamento.
            </p>
          </div>
        </div>
      </div>

      {/* Pantalla Completa */}
      {fullscreenImage && (
        <div className="fullscreen" onClick={closeFullscreen} style={fullscreenStyle}>
          <img src={fullscreenImage} alt="Fullscreen" style={imageStyle} />
        </div>
      )}
    </div>
  );
};

const fullscreenStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const imageStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
};
