
import './App.css';
import { MisRutas } from './router/MisRutas';
import { BrowserRouter} from "react-router-dom";

function App() {
  return (
    <>
    
    <BrowserRouter basename="/">
      <MisRutas />
    </BrowserRouter>
    </>
  );
}

export default App;
